import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

let id = 0;
function createData(date, version, information) {
  id += 1;
  return { id, date, version, information };
}

const rows = [
  createData(
    '03/06/2019',
    '1.6.0',
    'Integrated idle timer, and ability to disable notifications & plist is now located in the app resource folder.',
  ),
  createData('12/17/2018', '1.5.1', 'Created package installer.'),
  createData(
    '10/10/2018',
    '1.5.0',
    'Removed Firebase due to security concerns.',
  ),
  createData(
    '03/06/2018',
    '1.4.2',
    'Returned to firebase(bot blocking issues). Fixed constant pushing to server. Added up time progess bar. UI Changes.',
  ),
  createData(
    '10/25/2017',
    '1.4.1',
    'Switched database to MySQL via PHP, added custom database URL. New app icon.',
  ),
  createData(
    '09/08/2017',
    '1.4.0',
    'Added a plist to add fully customizable TimeUp settings. Including restart prompt intervals, timers, database, blacklisted apps, message, etc.',
  ),
  createData(
    '05/01/2017',
    '1.3.2',
    'First official release. Notifications for 7, 10, 14 or 21 days. Custom restart/shutdown timers with count down. Firebase tracking of uptime for all machines.',
  ),
  createData('12/15/2016', '1.0.0', 'Initial release.'),
];

function SimpleTable(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.version}</TableCell>
              <TableCell>{row.information}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);

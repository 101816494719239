import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import FlashOn from '@material-ui/icons/FlashOn';
import SystemUpdate from '@material-ui/icons/SystemUpdate';
import Refresh from '@material-ui/icons/Refresh';

const styles = theme => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2,
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  media: {
    padding: 0,
    height: 150,
    width: 150,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const projects = [
  {
    title: 'Speeds Increase',
    icon: 'speed',
    description: [
      'Restarting you computer regularly keeps your computer running more smoothly.'
    ]
  },
  {
    title: 'Stay Updated',
    icon: 'update',
    description: [
      'Restarting you computer regularly keeps your applications up to date.'
    ]
  },
  {
    title: 'Restart Reminders',
    icon: 'refresh',
    description: [
      'Reminders start after 7 days then increasing in frequence to help remind you to restart.'
    ]
  }
];

function SimpleCard(props) {
  const { classes } = props;

  return (
    <div>
      <Grid container spacing={40} alignItems="flex-end">
        {projects.map(tier => (
          // Enterprise card is full width at sm breakpoint
          <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
            <Card>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                action={tier.icon === 'speed' ? <FlashOn /> : tier.icon === 'update' ? <SystemUpdate /> : tier.icon === 'refresh' ? <Refresh /> : null}
                className={classes.cardHeader}
              />
              <CardContent>
                {tier.description.map(line => (
                  <Typography variant="subtitle1" align="center" key={line}>
                    {line}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleCard);

import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  toolbarTitle: {
    flex: 1,
  },
});

function NavBar(props) {
  const { classes } = props;

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.toolbarTitle}
          >
            TimeUp
          </Typography>
          <Button
            component={props => <Link to="/" {...props} />}
            color="inherit"
          >
            About
          </Button>
          <Button
            component={props => <Link to="/news" {...props} />}
            color="inherit"
          >
            News
          </Button>
          <Button
            component={props => <Link to="/docs" {...props} />}
            color="inherit"
          >
            Docs
          </Button>
          <Button
            component={props => <Link to="/contact" {...props} />}
            color="inherit"
          >
            Contact
          </Button>
          <Button
            target="_blank"
            href="https://github.com/EvanTrow/TimeUp/releases"
            color="inherit"
          >
            Download
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(NavBar);

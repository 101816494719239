import React from 'react';
import ReactPlayer from 'react-player';

function Video() {
  return (
    <ReactPlayer
      className="react-player"
      width="100%"
      url="/static/media/promo.mp4"
      volume="0.50"
      controls
    />
  );
}

export default Video;

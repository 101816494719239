import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import NavBar from './components/NavBar';
import About from './components/Pages/Home';
import Docs from './components/Pages/Docs';
import News from './components/Pages/News';
import Contact from './components/Pages/Contact';
import NotFound from './components/Pages/NotFound';
import Footer from './components/Footer';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

function Trowbridge(props) {
  const { classes } = props;

  return (
    <Router>
      <React.Fragment>
        <CssBaseline />
        <NavBar />
        <main className={classes.layout}>
          <Switch>
            <Route exact path="/docs" component={Docs} />
            <Route exact path="/news" component={News} />
            <Route exact path="/" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route component={NotFound} />
          </Switch>
        </main>
        <Footer />
      </React.Fragment>
    </Router>
  );
}

Trowbridge.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Trowbridge);

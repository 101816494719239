import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

let id = 0;
function createData(preference, description, datatype, defaultValue) {
  id += 1;
  return { id, preference, description, datatype, defaultValue };
}

const rows = [
  createData(
    'timerInterval',
    'How often all restart prompt checks are run. Unit in seconds.',
    'Number',
    '5',
  ),
  createData(
    'firstLow',
    'Amount of time until the first restart prompt will be displayed. Unit in days.',
    'Number',
    '7',
  ),
  createData(
    'firstHigh',
    'Amount of time until the first restart prompt will stop being displayed. Unit in days.',
    'Number',
    '10',
  ),
  createData(
    'firstInterval',
    'How often the first restart prompt within the specified days. Unit in seconds.',
    'Number',
    '21600',
  ),
  createData(
    'secondLow',
    'Amount of time until the second restart prompt will be displayed. Unit in days.	',
    'Number',
    '10',
  ),
  createData(
    'secondHigh',
    'Amount of time until the second restart prompt will stop being displayed. Unit in days.',
    'Number',
    '14',
  ),
  createData(
    'secondInterval',
    'How often the second restart prompt within the specified days. Unit in seconds.',
    'Number',
    '10800',
  ),
  createData(
    'thirdLow',
    'Amount of time until the third restart prompt will be displayed. Unit in days.',
    'Number',
    '14',
  ),
  createData(
    'thirdHigh',
    'Amount of time until the third restart prompt will stop being displayed. Unit in days.',
    'Number',
    '21',
  ),
  createData(
    'thirdInterval',
    'How often the third restart prompt within the specified days. Unit in seconds.',
    'Number',
    '3600',
  ),
  createData(
    'forthHigh',
    'Amount of time until the forth restart prompt will be displayed. Unit in days.',
    'Number',
    '21',
  ),
  createData(
    'forthInterval',
    'How often the forth restart prompt within the specified days. Unit in seconds.',
    'Number',
    '300',
  ),
  createData(
    'restartTimer',
    'Amount of time until the computer will restart automatically after having a restart prompt opened. Unit in seconds.',
    'Number',
    '150',
  ),
  createData(
    'shutdownTimer',
    'Amount of time until the computer will shut down automatically after having a shut down prompt opened. Unit in seconds.',
    'Number',
    '150',
  ),
  createData(
    'orangeIconDays',
    'Amount of time until the toolbar TimeUp icon will change from green to orange. Unit in days',
    'Number',
    '5',
  ),
  createData(
    'redIconDays',
    'Amount of time until the toolbar TimeUp icon will change from orange to red. Unit in days.',
    'Number',
    '10',
  ),
  createData(
    'daysUnit',
    'Unit displayed next to up time value.',
    'String',
    'd',
  ),
  createData(
    'restartCancelLimit',
    'Amount of time until the restart prompt cancel button is disbled (forcing restart after restartTimer hits 0). Unit in days.',
    'Number',
    '21',
  ),
  createData(
    'restartMsg',
    'Message displayed in restart prompt.',
    'String',
    'If you do nothing, the computer will restart automatically in',
  ),
  createData(
    'shutdownMsg',
    'Message displayed in shut down prompt.',
    'String',
    'If you do nothing, the computer will shut down automatically in',
  ),
  createData(
    'popoverMessage',
    'Message displayed in popover description.',
    'String',
    'Restarting your computer regularly keeps your applications up to date and your computer running smoothly.',
  ),
  createData(
    'blacklistApps',
    'Blocks restart prompts while certain applications are running. The array contains process names.',
    'Array',
    'Array is empty by default. Add task name.',
  ),
];

function SimpleTable(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Preference</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Data Type</TableCell>
            <TableCell align="right">Default Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.preference}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.datatype}</TableCell>
              <TableCell>{row.defaultValue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NewsTable from '../NewsTable';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 1}px`,
  },
});

function HeaderInfo(props) {
  const { classes } = props;

  return (
    <div>
      <div className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          News
        </Typography>
      </div>
      <NewsTable />
    </div>
  );
}

HeaderInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderInfo);

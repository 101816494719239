import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DocTable from '../DocTable';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 1}px`,
  },
});

function HeaderInfo(props) {
  const { classes } = props;

  return (
    <div>
      <div className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Documentation
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          component="p"
        >
          TimeUp can be fully customized to your needs.
        </Typography>
        <Typography align="center" color="textSecondary" component="p">
          * Preference plist is location in: {'{App Directory}'}/TimeUp.app
          /Contents/Resources/Preferences.plist
        </Typography>
        <Typography align="center" color="textSecondary" component="p">
          * To restore default settings please reinstall the app.
        </Typography>
      </div>
      <DocTable />
    </div>
  );
}

HeaderInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderInfo);

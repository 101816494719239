import React from 'react';
import Header from '../Header';
import Projects from '../Projects';

export default function Home() {
  return (
    <div>
      <Header />
      <Projects />
    </div>
  );
}
